import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from "vuex";

Vue.use(Vuex)

export default new Vuex.Store(
{
	state: 
	{
		value:'theater-box',
		details:{}
	},
	mutations: 
	{
		setValue(state,data)
		{
			state.value=data;
		},
		setDetails(state,data)
		{
			state.details=data;
		}
	},
	actions: 
	{
		Value({commit},data)
		{
			commit("setValue",data)
		},
		Details({commit},data)
		{
			commit("setDetails",data)
		}
	},
	modules: 
	{
		
	},
	getters: 
	{
		
	},
})