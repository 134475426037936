<template>
    <div class="home">
        <!-- 头部logo -->
        <headLogo @navigation="navigation"></headLogo>

        <!-- 轮播图 -->

        <div
            class="slideshowHomePC"
            style="margin-top: 96px">
            <slideshowBox></slideshowBox>
            <!-- <van-swipe
			  style="height: 715px"
			  :autoplay="autoplay"
			  indicator-color="white"
			  @change="onChange"
			>
			  <van-swipe-item v-for="(item, index) in slideshowImg" :key="index">
			    <img v-lazy="item.imgUrl" v-show="item.type =='img'" @click="link(item)" class="slideshowHomePC-img"/>
			    <video
			      :id="'video' + index"
			      controls="controls"
			      v-show="item.type =='video'"
			      loop="loop"
				  @click="videoClick" 
				   :muted="true"
				  class="slideshowHomePC-img">
			      <source :src="item.imgUrl" />
			    </video>
			  </van-swipe-item>
			</van-swipe> -->
        </div>

        <!-- 画布 -->
        <div class="coverageHomePC">
            <el-image
                class="coverageHomePC-img"
                src="/home/coverage.png"></el-image>
            <el-image
                class="coverageHomePC-newsimg"
                src="/index/xinwen.png"></el-image>
        </div>

        <!-- 新闻 -->
        <div class="newsHomePc">
            <!-- 新闻-信息 -->
            <div class="NewsMessageHomePc">
                <div class="NewsMessageHomePcinformation">
                    <div class="NewsMessageHomePcinformationData">
                        <div class="NewsMessageHomePcinformationLeft"></div>
                        <div class="NewsMessageHomePcinformationCenter">
                            <p class="NewsMessageHomePctitle">News</p>
                        </div>
                        <div class="NewsMessageHomePcinformationLeft"></div>
                    </div>
                    <p class="NewsMessageHomePcmessage">
                        {{ newsmessage }}
                    </p>
                    <el-image
                        class="NewsMessageHomePcchainimg"
                        src="/home/chain.png"></el-image>
                </div>
            </div>
            <!-- 新闻-数据 -->
            <el-row>
                <el-col
                    :span="8"
                    v-for="(item, id) in ListData"
                    :key="id"
                    class="newsPublicityPc-dataall">
                    <div
                        class="news-data"
                        @click="newsdetails(item)">
                        <el-row class="news-data-extend">
                            <img
                                :src="newsNotLoadImage"
                                :onload="
                                    onLoadImg(item.pictureUrl, '??x-oss-process=image/resize,w_370')
                                "
                                class="newsdata-img" />
                            <el-image
                                class="newsdata-coverageimg"
                                src="/home/newCoverage.png"></el-image>
                            <p class="newsdata-threeLogoimg"></p>
                            <div class="newsdata-titleMessage">
                                <p class="newsdata-title">{{ item.title }}</p>
                                <p class="newsdata-date">
                                    <span>{{ item.month }}</span>
                                    <span>{{ item.time }},</span>
                                    <span>{{ item.year }}</span>
                                </p>
                            </div>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-row class="newsHomePC-buttonbox">
                <el-button
                    class="newsHomePC-button"
                    round
                    @click="load"
                    >Learn More</el-button
                >
            </el-row>
        </div>

        <!-- 剧院 -->
        <homeTheaterdata :toType="type"></homeTheaterdata>

        <!-- 画布 -->
        <div class="coverageHomePC">
            <el-image
                class="coverageHomePC-img"
                src="/home/coverage.png"></el-image>
            <el-image
                class="coverageHomePC-newsimg"
                src="/index/evenas.png"></el-image>
        </div>

        <!-- 特色事件 -->
        <div class="evenasHomePC">
            <el-row>
                <el-col
                    :span="24"
                    class="evenasHomePC-title">
                    Feature Events
                </el-col>
            </el-row>
            <el-row class="chainboxHomePC">
                <el-col
                    :span="8"
                    :offset="8">
                    <el-image
                        class="evenasHomePC-chainimg"
                        src="/home/chain.png"></el-image>
                </el-col>
            </el-row>
            <!-- 特色事件-数据 -->
            <el-row>
                <el-col
                    :span="12"
                    v-for="(item, id) in evenasListData"
                    :key="id">
                    <div
                        class="newsTwo-data"
                        @click="newsdetails(item)">
                        <el-row class="newsTwo-data-extend">
                            <img
                                :src="eventsNotLoadImage"
                                :onload="
                                    onLoadImg(item.pictureUrl, '?x-oss-process=image/resize,w_570')
                                "
                                class="newsTwodata-img" />
                            <el-image
                                class="newsTwodata-coverageimg"
                                src="/home/newCoverage.png"></el-image>
                            <p class="newsTwodata-logoimg"></p>
                            <div class="newsTwodata-TitleMessage">
                                <p class="newsTwodata-title">{{ item.title }}</p>
                                <p
                                    class="newsTwodata-message"
                                    v-html="item.bodyContent" />
                            </div>
                            <p class="newsTwodata-date">
                                <span>{{ item.time }}</span>
                                <span>{{ item.month }}</span>
                                <span>{{ item.year }}</span>
                            </p>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <!-- AD Banne -->
        <div class="ADBanneHomePC">
            <el-carousel height="340px">
                <el-carousel-item
                    v-for="(item, index) in bottomSlideshowImg"
                    :key="index">
                    <img
                        :src="bannerNotLoadImage"
                        :onload="onLoadImg(item, '?x-oss-process=image/resize,h_340')"
                        class="bottomimgHomePC"
                        @click="linkBottom(item)" />
                </el-carousel-item>
            </el-carousel>
        </div>

        <!-- 画布 -->
        <div class="coverageHomePC">
            <el-image
                class="coverageHomePC-img"
                src="/home/coverage.png"></el-image>
            <el-image
                class="coverageHomePC-newsimg"
                src="/index/notice.png"></el-image>
        </div>

        <!-- 公告 -->
        <div class="noticesHomePC">
            <el-row>
                <el-col
                    :span="24"
                    class="evenasHomePC-title">
                    Notices
                </el-col>
            </el-row>
            <el-row>
                <el-col
                    :span="24"
                    class="noticesHomePC-databox">
                    <div class="noticesHomePC-data">
                        <template v-if="!!noticesList">
                            <p v-html="noticesList.bodyContent" />
                        </template>
                    </div>
                    <div class="noticesHomePC-buttonbox">
                        <el-button
                            class="noticesHomePC-button"
                            round
                            @click="notices"
                            >Learn More</el-button
                        >
                    </div>
                </el-col>
                <el-col :span="4"></el-col>
            </el-row>
        </div>

        <!-- 目录导航 -->
        <meauDialog
            :meauValue="navigationvalue"
            @meauFn="menu"></meauDialog>

        <!-- bottom -->
        <bottomNavigation></bottomNavigation>
    </div>
</template>
<script>
// 图片查询接口
import {
    selectPictureListById,
    selectContentListById,
    selectFeaturedEvenasById
} from '@/api/index.js'

// 头部组件
import headLogo from '@/components/headLogo.vue'
// 底部导航
import bottomNavigation from '@/components/bottomNavigation.vue'
// 菜单导航
import meauDialog from '@/components/meauDialog.vue'
// 剧院模块
import homeTheaterdata from '@/components/homeTheaterdata.vue'
// 轮播图组件
import slideshowBox from '@/components/slideshowBox.vue'
export default {
    data() {
        return {
            // 导航值
            navigationvalue: false,
            focusImgData: '/home/logonot.png',
            noticesValue: false,

            type: 'PC',

            // 首页轮播图
            slideshowImg: [],
            autoplay: 3000,
            videoTime: '',
            isautoplay: false,

            // 底部轮播图
            bottomSlideshowImg: [],

            newList: [],
            evenasList: [],
            noticesList: {},
            newsmessage:
                'What’s Going On in Theatre Network, Productions, Ticketing Platform, Outreach & Education, International Collaboration, and Talents Up-bringing.',

            clientHeight: '',

            // 轮播图数据
            list: [],
            bottomList: [],

            newsNotLoadImage: '/PCimage/370_424.jpg', //新闻图片未加载出来
            eventsNotLoadImage: '/PCimage/570_428.jpg', //事件图片未加载出来
            bannerNotLoadImage: '/PCimage/banner.jpg' //banner图片未加载出来
        }
    },
    components: {
        headLogo,
        bottomNavigation,
        meauDialog,
        homeTheaterdata,
        slideshowBox
    },
    computed: {
        // 新闻
        ListData() {
            let data = []
            if (this.newList.length > 3) {
                data = this.newList.slice(0, 3)
                return data
            } else {
                data = this.newList
                return data
            }
        },
        // 特色事件
        evenasListData() {
            let data = []
            if (this.evenasList.length > 6) {
                data = this.evenasList.slice(0, 6)
                return data
            } else {
                data = this.evenasList
                return data
            }
        }
    },
    created() {
        this.imgList()
        this.bottomImgList()
        this.getNewsList()
        this.getEvenasList()
        this.getNoticesList()
    },
    methods: {
        // 首页轮播图
        imgList() {
            selectPictureListById({ chnanelType: 1, picturetype: 1 }).then(response => {
                this.list = response.data.data
                response.data.data.map(v => {
                    if (v.pictureUrl != '' && v.pictureUrl != null) {
                        let data = { imgUrl: v.pictureUrl, type: 'img', pictureLink: true }
                        this.slideshowImg.push(data)
                    }
                    if (v.videoUrl != null && v.videoUrl != '') {
                        let data = { imgUrl: v.videoUrl, type: 'video', pictureLink: false }
                        this.slideshowImg.push(data)
                    }
                })
                console.log(this.slideshowImg, 9999)
            })
        },
        // 底部轮播图
        bottomImgList() {
            selectPictureListById({ chnanelType: 1, picturetype: 2 }).then(response => {
                this.bottomList = response.data.data
                response.data.data.map(v => {
                    this.bottomSlideshowImg.push(v.pictureUrl)
                })
            })
        },
        // 获取NEWS新闻数据
        getNewsList() {
            selectContentListById({
                chnanelType: 1,
                isIndex: 1,
                moduleType: 'MODULE_WHAT'
            }).then(response => {
                this.newList = response.data.data
                this.newList.map(v => {
                    this.time(v)
                })
            })
        },
        // 获取特色事件数据
        getEvenasList() {
            // selectContentListById(
            // {
            //   chnanelType: 1,
            //   isIndex: 1,
            //   moduleType: 'MODULE_PERFORMANCES'
            // })
            // .then((response)=>
            // {
            // 	this.evenasList=response.data.data;
            // 	this.evenasList.map((v)=>
            // 	{
            // 		this.time(v)
            // 	})
            // })
            selectFeaturedEvenasById({
                chnanelType: 2
            }).then(response => {
                this.evenasList = response.data.data
                this.evenasList.map(v => {
                    this.time(v)
                })
            })
        },
        // 获取公告数据
        getNoticesList() {
            selectContentListById({
                chnanelType: 1,
                isIndex: 1,
                moduleType: 'MODULE_BRESAKING'
            }).then(response => {
                this.noticesList = response.data.data[0]
            })
        },

        // 头部传来的数据
        navigation(v) {
            this.navigationvalue = v
        },

        // 跳转到新闻页面
        load() {
            this.$router.push('/news')
        },
        // 进入新闻详情
        newsdetails(item) {
            localStorage.setItem('Details', JSON.stringify(item))
            this.$router.push({
                path: '/noticesDetails',
                query: {
                    item: item,
                    headTitle: 'WHAT`S ON',
                    headImg: '/index/list.png'
                }
            })
        },

        // 特色事件
        eveLoad() {
            this.$router.push('/news')
        },

        // 显示公告
        notices() {
            this.$router.push('/notices')
        },
        // 遮罩层传回来的数据
        menu(v) {
            this.navigationvalue = v
        },

        // 跳转连接
        link(item) {
            if (item.pictureLink == true) {
                this.list.map(v => {
                    if (v.pictureUrl == item.imgUrl) {
                        if (v.url != null && v.url != '') {
                            window.location.href = v.url
                        }
                    }
                })
            }
        },

        // 底部跳转
        linkBottom(item) {
            this.bottomList.map(v => {
                if (v.pictureUrl == item) {
                    window.location.href = v.url
                }
            })
        },
        // 监听轮播图改变
        onChange(index) {
            // 如果是视频,就等播放完再进行下一个
            if (this.slideshowImg[index].type == 'video') {
                this.videoTime = document.getElementById(`video${index}`)
                this.videoTime.currentTime = 0
                if (this.videoTime.currentTime == 0) {
                    this.autoplay = 5000
                    this.isautoplay = false
                } else {
                    this.autoplay = (this.videoTime.duration - this.videoTime.currentTime) * 1000
                    this.isautoplay = true
                }
            } else {
                // 如果是图片就暂停三秒
                this.autoplay = 3000
            }
        },
        videoClick() {
            console.log('这里是PC')
            this.isautoplay = !this.isautoplay
            if (this.isautoplay == true) {
                this.autoplay = (this.videoTime.duration - this.videoTime.currentTime) * 1000
            } else {
                this.autoplay = 3000
            }
        },

        // 时间
        time(v) {
            v.pulish = v.publish.split('-')
            if (v.pulish[1] == '01') {
                v.pulish[1] = 'JAN'
            } else if (v.pulish[1] == '02') {
                v.pulish[1] = 'FEB'
            } else if (v.pulish[1] == '03') {
                v.pulish[1] = 'MAR'
            } else if (v.pulish[1] == '04') {
                v.pulish[1] = 'APR'
            } else if (v.pulish[1] == '05') {
                v.pulish[1] = 'MAY'
            } else if (v.pulish[1] == '06') {
                v.pulish[1] = 'JUN'
            } else if (v.pulish[1] == '07') {
                v.pulish[1] = 'JUL'
            } else if (v.pulish[1] == '08') {
                v.pulish[1] = 'AUG'
            } else if (v.pulish[1] == '09') {
                v.pulish[1] = 'SEP'
            } else if (v.pulish[1] == '10') {
                v.pulish[1] = 'OCT'
            } else if (v.pulish[1] == '11') {
                v.pulish[1] = 'NOV'
            } else if (v.pulish[1] == '12') {
                v.pulish[1] = 'DEC'
            }
            v.year = v.pulish[0]
            v.month = v.pulish[1]
            v.time = v.pulish[2]
        },
        // 图片加载完成时触发
        onLoadImg(imgSrc, resize) {
            return 'this.src=' + '"' + imgSrc + resize + '";this.οnlοad=null'
        }
    }
}
</script>

<style scoped>
@import '../assets/home.css'; /* 首页样式 */
@import '../assets/newsBox.css'; /* 新闻数据 */
@import '../assets/TwoDataBox.css'; /* 特色事件 */
</style>
