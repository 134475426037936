<template>
	<div id="app">
		<router-view v-if="isRouterAlive"></router-view>
	</div>
</template>

<script>
	export default 
	{
		name: "App",
		provide() 
		{
			return {
				routerReload: this.reload
			};
		},
		data() 
		{
			return {
				isRouterAlive: true
			};
		},
		methods: 
		{
			reload()
			{
				this.isRouterAlive = false;
				this.$nextTick(() => (this.isRouterAlive = true));
			}
		}
	};
</script>

<style>
	#app {
		font-family: Arial;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}

	#nav {
		padding: 30px;
	}

	#nav a {
		font-weight: bold;
		color: #2c3e50;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

	.theaterProM-chainimg {
		width: 100px;
		height: 10px;
	}

	.el-carousel__indicators--outside button {
		width: 8px;
		height: 8px;
		border-radius: 50%;
	}

	.el-carousel__indicator.is-active button {
		width: 8px !important;
		height: 8px !important;
		border-radius: 50%;
	}

	.van-swipe {
		height: 55vw !important;
	}

	.van-swipe__indicator {
		width: 8px !important;
		height: 8px !important;
	}

	.van-swipe__indicators {
		bottom: 5vw !important;
	}

	body {}

	button {
		color: #505050 !important;
	}

	/* 分页弹出来的样式 必须设置在全局样式表中 */
	li.el-select-dropdown__item.selected {
		color: #FDD000 !important;
	}
</style>
