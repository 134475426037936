// 引入axios
import axios from 'axios'

const env = process.env.NODE_ENV === 'production'

axios.defaults.baseURL = env ? '/api' : '/api'

// 添加请求拦截器
axios.interceptors.request.use(function (config) 
{
	// 在发送请求之前做些什么
	config.headers['Content-Type'] = 'application/json;charset=utf-8';
	config.headers['Channel'] = '111';
	return config;
}, 
function (error) 
{
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) 
{
	// 对响应数据做点什么
	return response;
},	
function (error) 
{
	// 对响应错误做点什么
	return Promise.reject(error);
});

// 抛出一个方法命名为http，参数为地址，类型，数据
export function http(url,type,data=null)
{
	// 判断请求类型为get时返回对应axios的请求方式
	if(type=='get')
	{
		return axios.get(url,data)
		// 判断请求类型为post时返回对应axios的请求方式
	}
	else if(type=='post')
	{
		return axios.post(url,data)
	}
}