import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		// 公告
		path: '/notices',
		name: 'Notices',
		component: function () 
		{
			return import('../views/Notices.vue')
		}
	},
	{
		// 公告详情
		path: '/noticesDetails',
		name: 'NoticesDetails',
		component: function () 
		{
			return import('../views/NoticesDetails.vue')
		}
	},
	{
		// 新闻
		path: '/news',
		name: 'News',
		component: function () 
		{
			return import('../views/News.vue')
		}
	},
	{
		// 剧院
		path: '/theaters',
		name: 'Theaters',
		component: function () 
		{
			return import('../views/Theaters.vue')
		}
	},
	{
		// 总经理图片
		path: '/presidentMessage',
		name: 'PresidentMessage',
		component: function () 
		{
			return import('../views/PresidentMessage.vue')
		}
	},
	{
		// 领导层
		path: '/leadership',
		name: 'Leadership',
		component: function () 
		{
			return import('../views/Leadership.vue')
		}
	},
	{
		// 价值观
		path: '/values',
		name: 'Values',
		component: function () 
		{
			return import('../views/Values.vue')
		}
	},
	{
		// 网络错误
		path: '/error',
		name: 'Error',
		component: function () 
		{
			return import('../views/Error.vue')
		}
	},
	{
		// 网络错误
		path: '*',
		name: 'Not',
		component: function () 
		{
			return import('../views/Not.vue')
		}
	},
	{
		// 关于我们
		path: '/aboutus',
		name: 'AboutUs',
		component: function () 
		{
			return import('../views/AboutUs.vue')
		}
	},
	{
		// 演出
		path: '/productions',
		name: 'Productions',
		component: function () 
		{
			return import('../views/Productions.vue')
		}
	},
	{
		// 剧目
		path: '/programs',
		name: 'Programs',
		component: function () 
		{
			return import('../views/Programs.vue')
		}
	},
	{
		// 教育与外联
		path: '/education',
		name: 'Education',
		component: function () 
		{
			return import('../views/Education.vue')
		}
	},
	{
		// 国际合作
		path: '/international',
		name: 'International',
		component: function () 
		{
			return import('../views/International.vue')
		}
	},
	{
		// 票务公司
		path: '/ticketing',
		name: 'Ticketing',
		component: function () 
		{
			return import('../views/Ticketing.vue')
		}
	},
	{
		// 联系我们
		path: '/contact',
		name: 'Contact',
		component: function () 
		{
			return import('../views/Contact.vue')
		}
	},
	
	
	
	// 移动端
	{
		// 移动端首页
		path: '/moveHomePage',
		name: 'MoveHomePage',
		component: function () 
		{
			return import('../views/move/MoveHomePage.vue')
		}
	},
	{
		// 移动端资讯列表
		path: '/moveNotices',
		name: 'MoveNotices',
		component: function () 
		{
			return import('../views/move/MoveNotices.vue')
		}
	},
	{
		// 移动端资讯详情
		path: '/moveNoticesDetails',
		name: 'MoveNoticesDetails',
		component: function () 
		{
			return import('../views/move/MoveNoticesDetails.vue')
		}
	},
	{
		// 移动端新闻列表
		path: '/moveNews',
		name: 'MoveNews',
		component: function () 
		{
			return import('../views/move/MoveNews.vue')
		}
	},
	{
		// 移动端关于我们
		path: '/moveAboutUs',
		name: 'MoveAboutUs',
		component: function () 
		{
			return import('../views/move/MoveAboutUs.vue')
		}
	},
	{
		// 移动端领导致辞
		path: '/movePresidentMessage',
		name: 'MovePresidentMessage',
		component: function () 
		{
			return import('../views/move/MovePresidentMessage.vue')
		}
	},
	{
		// 移动端领导
		path: '/moveLeadership',
		name: 'MoveLeadership',
		component: function () 
		{
			return import('../views/move/MoveLeadership.vue')
		}
	},
	{
		// 移动端价值观
		path: '/movevalues',
		name: 'MoveValues',
		component: function () 
		{
			return import('../views/move/MoveValues.vue')
		}
	},
	{
		// 移动端剧院管理
		path: '/movetheaters',
		name: 'MoveTheaters',
		component: function () 
		{
			return import('../views/move/MoveTheaters.vue')
		}
	},
	{
		// 移动端演出
		path: '/moveproductions',
		name: 'MoveProductions',
		component: function () 
		{
			return import('../views/move/MoveProductions.vue')
		}
	},
	{
		// 移动端剧目
		path: '/moveprograms',
		name: 'MovePrograms',
		component: function () 
		{
			return import('../views/move/MovePrograms.vue')
		}
	},
	{
		// 移动端教育与外联
		path: '/moveeducation',
		name: 'MoveEducation',
		component: function () 
		{
			return import('../views/move/MoveEducation.vue')
		}
	},
	{
		// 移动端国际合作
		path: '/moveinternational',
		name: 'MoveInternational',
		component: function () 
		{
			return import('../views/move/MoveInternational.vue')
		}
	},
	{
		// 移动端票务
		path: '/moveticketing',
		name: 'MoveTicketing',
		component: function () 
		{
			return import('../views/move/MoveTicketing.vue')
		}
	},
	{
		// 移动端联系我们
		path: '/movecontact',
		name: 'MoveContact',
		component: function () 
		{
			return import('../views/move/MoveContact.vue')
		}
	},
	{
		// 移动端网络错误
		path: '/moveerror',
		name: 'MoveError',
		component: function () 
		{
			return import('../views/move/MoveError.vue')
		}
	}
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 导航守卫
router.beforeEach((to,from,next)=>
{
	 // 兼容chrome
	document.body.scrollTop = 0
	// 兼容firefox
	document.documentElement.scrollTop = 0
	// 兼容safari
	window.pageYOffset = 0
	next()
})

export default router
