<template>
    <div id="slideshowBox">
        <!--  @mouseenter="MouseFun('移入')" @mouseleave="MouseFun('移出')" -->
        <div class="SwiperBox">
            <!-- 图片 -->
            <div v-for="(item, index) in slideshowImg">
                <img
                    v-lazy="item.imgUrl"
                    v-show="item.type == 'img'"
                    :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
                    @click="link(item)" />
                <video
                    :id="'video' + index"
                    controls="controls"
                    v-show="item.type == 'video'"
                    loop="loop"
                    :muted="true"
                    @click="videoClick"
                    :class="['imgCss', ShowImg == index ? 'ShowCss' : '']">
                    <source :src="item.imgUrl" />
                </video>
            </div>
            <!-- 左箭头按钮 -->
            <!-- <div class="leftBtn" @click="throttle(PrevFun)">&larr;</div> -->
            <!-- 右箭头按钮 -->
            <!-- <div class="rightBtn" @click="throttle(NextFun)">&rarr;</div> -->
            <!-- 下方指示点容器 -->
            <div class="instBox">
                <div
                    v-for="(item, index) in slideshowImg.length"
                    :key="index"
                    @click="ShowImg = index"
                    :class="['inst', ShowImg == index ? 'instActv' : '']"></div>
            </div>
        </div>
    </div>
</template>

<script>
// 图片查询接口
import { selectPictureListById, selectContentListById } from '@/api/index.js'

export default {
    components: {},
    data() {
        return {
            // 首页轮播图
            slideshowImg: [],
            // 轮播图数据
            list: [],
            autoplay: 3000,
            videoTime: '',
            isautoplay: false,

            ShowImg: 0, // 表示当前显示的图片
            flag: true, // 用来节流防止重复点击
            start: null // 自动执行下一张定时器
        }
    },
    mounted() {},
    created() {
        this.imgList()
        this.setTimeoFun()
    },
    methods: {
        // 首页轮播图
        imgList() {
            selectPictureListById({
                chnanelType: 1,
                picturetype: 1
            }).then(response => {
                this.list = response.data.data
                response.data.data.map(v => {
                    if (v.pictureUrl != '' && v.pictureUrl != null) {
                        let data = {
                            imgUrl: v.pictureUrl + '?x-oss-process=image/resize,w_1890,',
                            type: 'img',
                            pictureLink: true
                        }
                        this.slideshowImg.push(data)
                    }
                    if (v.videoUrl != null && v.videoUrl != '') {
                        let data = {
                            imgUrl: v.videoUrl,
                            type: 'video',
                            pictureLink: false
                        }
                        this.slideshowImg.push(data)
                    }
                })
                console.log(this.slideshowImg, 9999)
            })
        },
        videoClick() {
            console.log('这里是PC')
            this.isautoplay = !this.isautoplay
            console.log(this.isautoplay)
            if (this.isautoplay == true) {
                this.autoplay = (this.videoTime.duration - this.videoTime.currentTime) * 1000
                this.start = setInterval(() => {
                    this.NextFun()
                }, this.autoplay)
            } else {
                this.autoplay = 3000
                this.start = setInterval(() => {
                    this.NextFun()
                }, this.autoplay)
            }
        },

        // 跳转连接
        link(item) {
            if (item.pictureLink == true) {
                this.list.map(v => {
                    if (v.pictureUrl == item.imgUrl) {
                        if (v.url != null && v.url != '') {
                            window.location.href = v.url
                        }
                    }
                })
            }
        },

        // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
        MouseFun(type) {
            // 停止定时器            // 重新执行定时器
            type == '移入' ? clearTimeout(this.start) : this.setTimeoFun()
        },
        setTimeoFun() {
            this.start = setInterval(() => {
                this.NextFun()
            }, 3000)
        },
        // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
        throttle(fun) {
            if (this.flag) {
                this.flag = false
                fun() // 此为模板中传递进来的PrevFun()或NextFun()函数
                setTimeout(() => {
                    this.flag = true
                }, 2000) // 节流间隔时间
            }
        },
        // 上一张
        PrevFun() {
            if (this.ShowImg !== 0) {
                this.ShowImg--
            } else {
                this.ShowImg = this.slideshowImg.length - 1
            }
        },
        // 下一张
        NextFun() {
            if (this.ShowImg !== this.slideshowImg.length - 1) {
                this.ShowImg++
            } else {
                this.ShowImg = 0
            }
        }
    }
}
</script>

<style>
/* 图片容器样式 */
.SwiperBox {
    position: relative;
    width: 100%;
    height: 715px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    /* cursor: pointer; */
}

/* 图片默认样式 */
.imgCss {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 715px;
    opacity: 0;
    transition: 2s;
    /* 淡入淡出过渡时间 */
}

/* 图片选中样式(继承上方默认样式) */
.ShowCss {
    opacity: 1;
}

/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(109, 109, 109, 0.445);
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
    font-size: 12px;
}

.leftBtn {
    left: 10px;
}

.rightBtn {
    right: 10px;
}

/* 下方指示器盒子 */
.instBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10px;
    display: flex;
}

/* 小圆点 */
.inst {
    width: 8px;
    height: 8px;
    border: 1px solid #ccc;
    margin-right: 8px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.inst:last-child {
    margin-right: 0px;
}

.instActv {
    border: 1px solid #ff0000;
    background: #ff0000;
}

#slideshowBox {
    width: 100%;
}
</style>
