import 'babel-polyfill'
import promise from 'es6-promise'
promise.polyfill()
import Vue from 'vue'

import router from './router'
import store from './store'
import App from './App.vue'

// Element UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// Vant
import { createApp } from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import VueLazyload from 'vue-lazyload'

import './utils/base.css'

import VideoPlayer from 'vue-video-player'

Vue.use(VideoPlayer)



Vue.use(VueLazyload , {
  preLoad: 1.3,
  attempt: 1
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
