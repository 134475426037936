<template>
    <div class="TheatreImgBox">
        <img
            src="/home/Images.png"
            class="TheatreBackgroundImg" />

        <div class="theaterHomeSildePc">
            <!-- 剧院-信息 -->
            <div class="theaterHomeSildePc-information">
                <div class="theaterHomeSildePc-informationData">
                    <div class="theaterHomeSildePc-informationLeft"></div>
                    <div class="theaterHomeSildePc-informationCenter">
                        <p class="theaterHomeSildePc-title">Theatres</p>
                    </div>
                    <div class="theaterHomeSildePc-informationLeft"></div>
                </div>

                <p class="theaterHomeSildePc-message">
                    {{ message }}
                </p>
                <el-image
                    class="theaterHomeSildePc-chainimg"
                    src="/home/chain.png"></el-image>
            </div>

            <!-- 剧院-轮播图 -->
            <div class="theaterHomeSildePc-slideshow">
                <el-row class="theaterHomeSildePc-slideshowHeight">
                    <el-carousel
                        :interval="4000"
                        type="card"
                        height="410px"
                        @change="silde"
                        arrow="always">
                        <el-carousel-item
                            v-for="(item, id) in theaterSlideshowdata"
                            :key="id"
                            class="theater-sli">
                            <div @click="jumpTheater(item)">
                                <el-image
                                    class="theaterHomeSildePc-slideshowimg"
                                    :src="item.pictureUrl"></el-image>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </el-row>
                <div
                    class="theaterHomeSildePc-list"
                    @click="jumpTheater(sildeData)">
                    <el-col
                        :span="14"
                        :offset="5"
                        class="theaterHomeSildePc-shanghai">
                        <template v-if="!!sildeData">
                            <p class="theaterHomeSildePc-shanghai-title">
                                {{ sildeData.theaterName }}
                            </p>
                            <p
                                class="theaterHomeSildePc-shanghai-message"
                                v-html="sildeData.bodyContent">
                                {{ sildeData.bodyContent }}
                            </p>
                        </template>
                    </el-col>
                </div>
            </div>
            <div class="theaterHomeSildePc-buttonbox">
                <button
                    @click="theaterDetails"
                    class="theaterHomeSildePc-button">
                    Learn More
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { selectContentListById, selectTheaterContentById } from '@/api/index.js'
export default {
    data() {
        return {
            sildeData: {},
            theaterSlideshowdata: [],
            message:
                'Pace-making Theatre Operator; China’s Largest Theatre Network with over 70 Theatres from over 22 Provinces under Direct Management.'
        }
    },
    props: {
        toType: String
    },
    created() {
        this.theatersList() // 剧院轮播数据
    },
    methods: {
        // 获取剧院数据
        theatersList() {
            selectTheaterContentById({
                chnanelType: 1
            }).then(response => {
                this.theaterSlideshowdata = response.data.data.map(item => {
                    item.pictureUrl = item.pictureUrl + '?x-oss-process=image/resize,w_950'
                    return item
                })
                this.getSlideshowData()
            })
        },
        getSlideshowData() {
            this.sildeData = this.theaterSlideshowdata[0]
        },
        // 获取轮播图滚动数据
        silde(item) {
            this.sildeData = this.theaterSlideshowdata[item]
        },
        // 进入剧院页面
        theaterDetails() {
            if (this.toType == 'PC') {
                this.$router.push('/theaters')
            } else {
                this.$router.push('/moveTheaters')
            }
        },
        // 进入详情
        jumpTheater(item) {
            this.$router.push({
                path: '/theaters',
                query: {
                    item: item
                }
            })
        }
    }
}
</script>

<style>
.TheatreImgBox {
    width: 100%;
    height: 1071px;
    position: relative;
    margin-top: 70px;
}
.TheatreBackgroundImg {
    width: 100%;
    height: 1071px;
}
.theaterHomeSildePc {
    height: 1000px;
    position: absolute;
    padding-top: 73px;
    bottom: 0px;
    width: 100%;
}
.theaterHomeSildePc-information {
    width: 1170px;
    height: 160px;
    border: solid 3px white;
    line-height: 35px;
    border-top: none;
    color: white;
    margin: 0 auto;
}
.theaterHomeSildePc-informationData {
    display: flex;
}
.theaterHomeSildePc-informationLeft {
    flex: 1;
    border-top: solid 2px white;
}
.theaterHomeSildePc-informationCenter {
    flex: 1;
    position: relative;
}
.theaterHomeSildePc-title {
    width: 100%;
    position: absolute;
    top: -15px;
    height: 36px;
    font-size: 48px;
}
.theaterHomeSildePc-message {
    width: 80%;
    height: 47px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 36px 0px 0px 10%;
    font-size: 18px;
    color: #fdfdfd;
    line-height: 25px;
}
.theaterHomeSildePc-chainimg {
    width: 128px;
    height: 13px;
    margin-top: 22px;
}
.theaterHomeSildePc-slideshow {
    margin-top: 36px;
    height: 650px;
}
.theaterHomeSildePc-slideshowimg {
    width: 100%;
    height: 410px;
    border-radius: 30px;
}
.theaterHomeSildePc-shanghai {
    width: 90%;
    margin-left: 5%;
    font-size: 14px;
    margin-top: 63px;
    overflow: hidden;
}
.theaterHomeSildePc-shanghai::v-deep img {
    max-width: 100%;
}
.theaterHomeSildePc-shanghai-title {
    font-size: 30px;
    margin-bottom: 20px;
}
.theaterHomeSildePc-shanghai-message {
    height: 80px;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    color: white !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.theaterHomeSildePc-shanghai-message p {
    color: white !important;
}
.theaterHomeSildePc-shanghai-message p span {
    color: white !important;
}
.theaterHomeSildePc-buttonbox {
    height: 50px;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 30px;
}
.theaterHomeSildePc-button {
    width: 400px;
    height: 50px;
    color: white !important;
    line-height: 10px;
    border: none;
    border-radius: 50px;
    background-color: transparent;
    border: solid 1px #f5f5f5;
    font-size: 24px;
    color: #ffffff;
    cursor: default !important;
}
.theaterHomeSildePc-list {
    color: white;
    height: 200px;
}
.theaterHomeSildePc-slideshowHeight {
    height: 450px;
}
.theaterHomeSildePc-slideshowHeight .el-carousel__mask {
    height: 100% !important;
    opacity: 0 !important;
}
.theaterHomeSildePc-slideshowHeight .el-carousel__arrow--left {
    left: 310px;
}
.theaterHomeSildePc-slideshowHeight .el-carousel__arrow--right {
    right: 310px;
}
.theaterHomeSildePc-slideshowHeight .el-carousel__arrow {
    width: 60px !important;
    height: 60px !important;
    background-color: rgba(31, 45, 61, 0.7) !important;
}
.el-icon-arrow-left {
    color: white !important;
    font-size: 25px !important;
}
.el-icon-arrow-right {
    color: white !important;
    font-size: 25px !important;
}
</style>
