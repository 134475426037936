<template>
	<div class="com-head">
		<el-row class="index-head">
			<el-col :span="5">
				<el-col :span="8">
					<div class="meau-imgbox" @click="navigation">
						<el-image class="meau-img" src="/home/meau.png"></el-image>
					</div>
				</el-col>
				<el-col :span="11">
					<div class="meau-title" @click="navigation">MENU</div>
				</el-col>
			</el-col>
			
			<el-col :span="14">
				<div class="logo-box" @click="logo">
					<el-image class="logo-img" src="/home/headlogo.png"></el-image>
				</div>
			</el-col>
			
			<el-col :span="2" :offset="3">
				<div class="cn-box" @click="toChinese">
					<el-image class="cn-img" src="/home/CN.png"></el-image>
				</div>
			</el-col>
		</el-row>
		
		
		<!-- 目录导航 -->
		<meauDialog></meauDialog>
	</div>
</template>

<script>

// 菜单导航
import meauDialog from '@/components/meauDialog.vue'
export default 
{
	data()
	{
		return{
			navigationvalue:false,
			ismeau:true,
		}
	},
	components: 
	{
		meauDialog,
	},
	created()
	{
		this.$emit('navigation',this.navigationvalue)
	},
	methods:
	{
		// 点击导航
		navigation()
		{
			this.ismeau=false;
			this.navigationvalue=true
			this.$emit('navigation',this.navigationvalue)
		},
		toChinese()
		{
			window.location.href = 'https://www.polytheatre.com/'
			window.location.replace('https://www.polytheatre.com/')
		},
		logo()
		{
			if(this.$route.path=='/')
			{
				console.log('为当前路由')
			}
			else
			{
				this.$router.push('/')
			}
		},
	
		// 关闭遮罩层
		closeEvent()
		{
			this.ismeau=true;
			this.navigationvalue=false;
			this.$emit('meauFn',this.navigationvalue)
		},
	}
}
</script>

<style scoped>
	/* 封装css */
	@import '../assets/headLogo.css';
	.com-head{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 100;
		background: #fff;
	}
	.closeIcon{
		width: 40px;
		float: left;
		margin-top: 2px;
	}
	.closeFont{
		float: left;
		font-size: 25px;
		line-height: 30px;
	}
	.el-icon-circle-close{
		font-size: 25px;
		color: white;
	}
</style>