import { http } from "@/utils/request.js"

// 保存留言信息
export function savaLeave(data)
{
	return http('/savaLeave','post',data)
}

// 根据渠道id和图片位置id查询图片
export function selectPictureListById(data)
{
	return http('/selectPictureListById','post',data)
}

// 根据渠道id和图片位置id查询内容
export function selectContentListById(data)
{
	return http('/selectContentListById','post',data)
}

// 内容管理 - 分页
export function contentList(data)
{
	return http('/contentList','post',data)
}

// 内容管理 - leaderShip
export function selectLeadershipById(data)
{
	return http('/selectLeadershipById','post',data)
}

// 首页 - 剧院信息
export function selectTheaterContentById(data)
{
	return http('/selectTheaterContentById','post',data)
}
// 首页 - 特殊事件
export function selectFeaturedEvenasById(data)
{
	return http('/selectFeaturedEvenasById','post',data)
}