<template>
	<div class='popContainer' v-if="navigationvalue">
		<div @click="close" class="close">
			<p class="closeIcon">
				<i class="el-icon-circle-close"></i>
			</p>
			<p class="closeFont">close</p>
		</div>
		<div class="popContainerData">
			<div @click="navigationClick('/')" class="router-link">
				<span @mouseover="Mouse">HOME</span>
			</div>
			<div @click="navigationClick('/aboutUs')" class="router-link">
				<span @mouseover="aboutMouse">ABOUT</span>
			</div>
			<div v-if="isAboutValue" id="jumpBox">
				<p @click="navigationClick('/aboutUs')" class="router-linkdata">Who we are</p>
				<p @click="navigationClick('/news')" class="router-linkdata">What's on</p>
				<p @click="navigationClick('/presidentMessage')" class="router-linkdata">Chair's message</p>
				<p @click="navigationClick('/leadership')" class="router-linkdata">Executives</p>
				<p @click="navigationClick('/values')" class="router-linkdata">Values</p>
			</div>
			<div @click="navigationClick('/theaters')" class="router-link">
				<span @mouseover="Mouse">THEATRES</span>
			</div>
			<div @click="navigationClick('/productions')" class="router-link">
				<span @mouseover="Mouse">PRODUCTIONS</span>
			</div>
			<div @click="navigationClick('/programs')" class="router-link">
				<span @mouseover="Mouse">PROGRAMS</span>
			</div>
			<div @click="navigationClick('/education')" class="router-link">
				<span @mouseover="Mouse">EDUCATION&OUTREACH</span>
			</div>
			<div @click="navigationClick('/ticketing')" class="router-link">
				<span @mouseover="Mouse">TICKETING</span>
			</div>
			<div @click="navigationClick('/international')" class="router-link">
				<span @mouseover="Mouse">INTERNATIONAL CLLABORATIONS</span>
			</div>
			<div @click="navigationClick('/contact')" class="router-link">
				<span @mouseover="Mouse">CONTACT</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default
	{
		inject: ["routerReload"],
		data()
		{
			return{
				// 导航值
				navigationvalue:false,
				isAboutValue:false,
			}
		},
		props:
		{
			meauValue:Boolean
		},
		watch:
		{
			meauValue: 
			{
				handler(newVal, oldVal)
				{
					this.navigationvalue=newVal;
				},
				immediate: true,
				deep: true,
			},
		},
		created()
		{
			this.isAboutValue=false;
		},
		methods:
		{
			// 关闭遮罩层
			close()
			{
				this.navigationvalue=false;
				this.isAboutValue=false;
				this.$emit('meauFn',this.navigationvalue)
			},
			aboutMouse()
			{
				this.isAboutValue=true;
				console.log(this.isAboutValue,'打开')
			},
			Mouse()
			{
				this.isAboutValue=false;
				console.log(this.isAboutValue,'关闭')
			},
			navigationClick(path)
			{
				this.$router.push(path)
				this.routerReload()
			},
		}
	}
</script>

<style scoped>
	.popContainer {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.8);
		z-index: 9999;
		padding-top: 50px;
		display: flex;
	}
	.popContainerData{
		width: 88%;
		margin-left: 6%;
		align-self: center;
	}
	.router-link{
		color: #ccc !important;
		font-size: 24px;
		font-family: Roboto;
		line-height: 50px;
	}
	.router-linkdata{
		color: #ccc !important;
		font-size: 20px;
		line-height: 40px;
	}
	.router-link :hover{
		color: white;
		line-height: 50px;
		font-size: 24px;
		font-family: Roboto;
	}
	.router-linkdata:hover{
		color: white;
		font-size: 20px;
		line-height: 40px;
	}
	.close{
		width: 100%;
		height: 30px;
		text-align: left;
		color: white;
		position: fixed;
		margin-left: 65px;
		margin-top: -20px;
	}
	.el-icon-circle-close{
		font-size: 25px;
		color: white;
	}
	.closeIcon{
		width: 40px;
		float: left;
		margin-top: 2px;
	}
	.closeFont{
		float: left;
		font-size: 25px;
		line-height: 30px;
	}
	@keyframes  ani{
	    0%{  opacity:0; }
	    100%{ opacity:1; }
	}
	.popContainerData div:nth-child(1){
	   animation: ani 0.5s both;
	}
	.popContainerData div:nth-child(2){
	   animation: ani 0.3s 0.3s both;
	}
	.popContainerData div:nth-child(3){
	   animation: ani 0.3s 0.6s both;
	}
	.popContainerData div:nth-child(4){
	   animation: ani 0.3s 0.9s both;
	}
	.popContainerData div:nth-child(5){
	   animation: ani 0.3s 1.2s both;
	}
	.popContainerData div:nth-child(6){
	   animation: ani 0.3s 1.5s both;
	}
	.popContainerData div:nth-child(7){
	   animation: ani 0.3s 1.8s both;
	}
	.popContainerData div:nth-child(8){
	   animation: ani 0.3s 2.1s both;
	}
	.popContainerData div:nth-child(9){
	   animation: ani 0.3s 2.4s both;
	}
	.popContainerData div:nth-child(10){
	   animation: ani 0.3s 2.7s both;
	}
	
	#jumpBox p:nth-child(1){
	   animation: ani 0.4s both;
	}
	#jumpBox p:nth-child(2){
	   animation: ani 0.4s 0.8s both;
	}
	#jumpBox p:nth-child(3){
	   animation: ani 0.4s 1.2s both;
	}
	#jumpBox p:nth-child(4){
	   animation: ani 0.4s 1.6s both;
	}
	#jumpBox p:nth-child(5){
	   animation: ani 0.4s 2s both;
	}
</style>